/* eslint-disable @typescript-eslint/no-explicit-any */

import { PayloadAction, isRejectedWithValue } from "@reduxjs/toolkit";
import { ConnectionState, error, ErrorCode, setConnectionState } from "@xcira/commons";

function handleError(action: any) {
  const { accessToken }: { accessToken: string } = (action.meta.arg as any).originalArgs;
  const errorCode = (action.payload as any)?.data?.error as ErrorCode;

  return error({
    accessToken,
    errorCode: errorCode,
  });
}

export function createErrorHandlerMiddleware() {
  return (store: any) => (next: any) => (action: PayloadAction<any>) => {
    if (isRejectedWithValue(action)) {
      const arg = action.meta.arg as any;
      switch (arg.endpointName) {
        case "followAuction": {
          const { accessToken }: { accessToken: string } = arg.originalArgs;

          store.dispatch(
            setConnectionState({
              accessToken,
              isLoggedIn: false,
              connectionState: (action.payload as any).data.error as ConnectionState,
            })
          );
          store.dispatch(handleError(action));
          break;
        }
        default: {
          store.dispatch(handleError(action));
          break;
        }
      }
    }

    return next(action);
  };
}
